import React, { ComponentType, FunctionComponent, useState } from 'react';
import { useNotify, useDataProvider, useTranslate } from 'react-admin';
import useConfig from '../../hooks/useConfig';

/**
 * Create a clickable button for each format to download competencies
 *
 * @param Component
 * @param params
 */
function withGoogleDocsCompetenciesFiles(
  Component: ComponentType<any>,
  params: Record<string, any> | undefined
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const t = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [generatedLinks, setGeneratedLinks] = useState<string | null>(null);
    const config = useConfig();
    const { resource = 'users', record, source } = props;

    const generateCompetencyFile = () => {
      if (!record?.[source]) {
        notify('notify.error.no_record', 'error');

        return;
      }

      const profileUrl = `${location.protocol}${config.uiUrl}/experts/${record[source]}`;
      const format = 'google_docs';
      const language = config.locale;
      const resourceToFetch = (params && params.resourceToFetch) || resource;

      setLoading(true);

      // TODO its a temporary react admin 3.19.4 fix it needs to be properly fixed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dataProvider
        .fetch(
          resourceToFetch,
          {
            path: `${record[source]}/download?type=${format}&language=${language}&profile_url=${profileUrl}`,
          },
          {
            method: 'GET',
          }
        )
        .then(({ data }: { data: { url: string } }) => {
          setGeneratedLinks(data?.url);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          notify('notify.error.download', 'error');
        });
    };

    const openGoogleDocs = () => {
      const link = generatedLinks;

      if (link) {
        window.open(link, '_blank', 'noopener,noreferrer');
      }
    };

    const button = [
      {
        onClick: () =>
          generatedLinks ? openGoogleDocs() : generateCompetencyFile(),
        label: generatedLinks
          ? t('company.section.competenciesFile.cta.google_docs')
          : t('company.section.competenciesFile.generateGoogleDoc'),
        disabled: loading,
        key: 'google_docs',
      },
    ];

    return <Component {...props} record={{ ...record, [source]: button }} />;
  };

  Extension.displayName = `withExtension(GoogleDocsCompetenciesFiles)`;

  return Extension;
}

export default withGoogleDocsCompetenciesFiles;
