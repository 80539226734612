import withMultipleLangFiles from './withMultipleLangFiles';
import withCompetenciesFiles from './withCompetenciesFiles';
import withGoogleDocsCompetenciesFiles from './withGoogleDocsCompetenciesFiles';
import withAttachmentsFiles from './withAttachmentsFiles';
import withMultiplyByHundred from './withMultiplyByHundred';
import withButtonLink from './withButtonLink';
import withMinuteFile from './withMinuteFile';
import withModalForm from './withModalForm';

const extensions = {
  multipleLangFiles: withMultipleLangFiles,
  competenciesFiles: withCompetenciesFiles,
  googleDocsCompetenciesFiles: withGoogleDocsCompetenciesFiles,
  attachmentsFiles: withAttachmentsFiles,
  multiplyByHundred: withMultiplyByHundred,
  buttonLink: withButtonLink,
  minuteFile: withMinuteFile,
  modalForm: withModalForm,
};

export default extensions;
